.cardTitulo {
  font-weight: bold;
  font-size: 16px !important;
}

.despachando {
  font-weight: bold;
  color: #5b00af !important;
}

.autorizado {
  font-weight: bold;
  color: #8003f5 !important;
}

.disponible {
  font-weight: bold;
  color: #1daf00 !important;
}

.defectuoso {
  font-weight: bold;
  color: #c71700 !important;
}

.impago {
  font-weight: bold;
  color: #f57e2f !important;
}

.solicitud {
  font-weight: bold;
  color: #e6cc3d !important;
}

.extraContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
