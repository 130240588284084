.extraContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contenidoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contenidoAlerta {
  color: red;
}

.ft-small {
  font-size: 15px;
}

.ft-big {
  font-size: 17px;
}

.cardTitulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px !important;
}
