.sidebar {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 220px;
  min-width: 220px;
  height: 100%;
  background-color: #ebebeb;
  overflow-y: scroll;
  box-shadow: 0px 0px 5px #bdbdbd;
}

.sidebarTop {
  flex: 1;
}

.sidebarLabel {
  flex-direction: column;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebarIcon {
  padding-right: 25px;
}

.sidebarHeader {
  font-size: 10px;
  font-weight: bold;
  margin-top: 25px;
}
