.pageContainer {
  flex-direction: column;
  display: flex;
  flex: 1;
  /* justify-content: center; */
  /* background-color: red; */
}

.tableContainer {
  padding-top: 20px;
  width: 100%;
}

.tableFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subtitle {
  padding-left: 20px;
  font-weight: bold;
}

.carga {
  color: green;
}

.descarga {
  color: red;
}

.ajuste {
  color: gray;
}
