.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: lightgrey;
}

.footerRegister {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: lightgrey;
}

.msgError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.linkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: rgb(51, 102, 153);
}
