.msgError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.containerEmail {
  margin: 15px;
}

.linkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: rgb(51, 102, 153);
}
