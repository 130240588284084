.footer {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  color: lightgrey;
}

.footerRegister {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: lightgrey;
}

.linkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: rgb(51, 102, 153);
}
