.headerContainer {
  display: flex;
  align-items: stretch;
}

.titulo {
  flex: 1;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.sede {
  display: flex;
  justify-content: center;
  align-items: center;
}
