.bodyContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  min-width: 600px;
}

.mainContainer {
  display: flex;
  flex: 1;
}

.msgOfflineContainer {
  display: flex;
  flex: 1;
  background-color: red;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
  width: 100%;
}

/* .mainContainer::-webkit-scrollbar {
  display: none;
} */
